import { T } from "@/locales";

export default {
  number: [
    { required: true, message: T("请输入库位编号"), trigger: "change" },
    { max: 32, message: T("超出最大长度 (32)"), trigger: "change" },
  ],
  reservoir_area: [{ required: true, message: T("请选择库区"), trigger: "change" }],
  remark: [{ max: 256, message: T("超出最大长度 (256)"), trigger: "change" }],
};
