<template>
  <div>
    <a-modal v-model="visible" width="960px" :confirmLoading="loading" :maskClosable="false" @cancel="cancel" @ok="confirm">
      <div slot="title">{{ form.id ? $t("编辑库位") : $t("新增库位") }}</div>
      <div>
        <a-form-model ref="form" :model="formData" :rules="rules" :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }">
          <a-row gutter="16">
            <a-col :span="24" :md="12">
              <a-form-model-item prop="number" :label="$t('库位编号')">
                <a-input v-model="formData.number" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="reservoir_area" :label="$t('库区')">
                <reservoir-area-select
                  v-model="formData.reservoir_area"
                  :warehouse="formData.warehouse"
                ></reservoir-area-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="area_number" :label="$t('货架/区域号')">
                <a-input v-model="formData.area_number" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="row" :label="$t('行号')">
                <a-input-number v-model="formData.row" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="col" :label="$t('列号')">
                <a-input-number v-model="formData.col" style="width: 100%" />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="temperature_upper" :label="$t('温度上限')">
                <a-input-number v-model="formData.temperature_upper" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="temperature_lower" :label="$t('温度下限')">
                <a-input-number v-model="formData.temperature_lower" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="humidity_upper" :label="$t('湿度上限')">
                <a-input-number v-model="formData.humidity_upper" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="humidity_lower" :label="$t('湿度下限')">
                <a-input-number v-model="formData.humidity_lower" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="weight_capacity" :label="$t('最大承重')">
                <a-input-number v-model="formData.weight_capacity" :min="0" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="is_active" :label="$t('状态')">
                <a-select v-model="formData.is_active" style="width: 100%">
                  <a-select-option :value="true">{{ $t("激活") }}</a-select-option>
                  <a-select-option :value="false">{{ $t("冻结") }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="remark" :label="$t('备注')">
                <a-input v-model="formData.remark" allowClear />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { locationCreate, locationUpdate, getLocationsNumber } from "@/api/data";
import rules from "./rules.js";

export default {
  components: {
    ReservoirAreaSelect: () => import("@/components/ReservoirAreaSelect/index"),
  },
  props: ["visible", "form"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      rules,
      loading: false,
      formData: { layer_number: null, column_number: null },
    };
  },
  methods: {
    confirm() {
      if (
        this.formData.temperature_upper != undefined &&
        this.formData.temperature_lower != undefined &&
        this.formData.temperature_upper < this.formData.temperature_lower
      ) {
        this.$message.error(this.$t("温度下限不能大于温度上限"));
        return;
      }

      if (
        this.formData.humidity_upper != undefined &&
        this.formData.humidity_lower != undefined &&
        this.formData.humidity_upper < this.formData.humidity_lower
      ) {
        this.$message.error(this.$t("湿度下限不能大于湿度上限"));
        return;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let func = this.formData.id ? locationUpdate : locationCreate;

          func(this.formData)
            .then((data) => {
              this.$message.success(this.formData.id ? this.$t("修改成功") : this.$t("新增成功"));
              this.$emit(this.formData.id ? "update" : "create", data);
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },
  },
  watch: {
    visible(status) {
      if (status) {
        if (this.form.id) {
          this.formData = { ...this.form };
        } else {
          getLocationsNumber().then((data) => {
            this.formData = {
              is_active: true,
              number: data.number,
            };
          });
        }
      }
    },
  },
};
</script>

<style scoped></style>
